import axios from 'axios';

// export const getAllDashboards = async (token) => {
//   const response = await axios.get('https://api.powerbi.com/v1.0/myorg/dashboards', {
//     headers: {
//       Authorization: `Bearer ${token}`
//     }
//   });
// }

export const getAllReports = async (token, groupId) => {
  const response = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

export const getGroups = async (token) => {
  const response = await axios.get('https://api.powerbi.com/v1.0/myorg/groups', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export const getReportPages = async (token, reportId) => {
  const response = await axios.get(`https://api.powerbi.com/v1.0/myorg/reports/${reportId}/pages`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}
