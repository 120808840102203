<template>
  <div style="height: 100%;">
    <v-container fluid style="height: 100%; justify-content: center" class="pa-0">
      <v-row style="height: 90%" class="ma-0 flex-column flex-nowrap">
        <v-col
          cols="12"
          class="pa-0"
        >
          <div ref="power_bi_container" style="width: 100%;height: calc(100vh - 136px);"
               class="d-flex align-center justify-center">
            <div v-if="!selectedReport" class="text-center pt-4">
              No report selected yet. Select your workspace and choose one of the reports.
            </div>
          </div>
        </v-col>
      </v-row>
      <v-bottom-navigation
        color="teal"
        grow
        fixed
      >
        <v-menu
          top
          close-on-click
          offset-y
          z-index="100"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs"
                   v-on="on"
            :loading="loadingGroups">
              <span>Workspace</span>
              <v-icon>$dashboard</v-icon>
            </v-btn>
          </template>
          <v-list max-height="200">
            <v-list-item-group
              v-model="selectedGroup"
              style="background-color: #ffffff;"
              color="primary"
            >
              <v-list-item
                v-for="item in powerBIGroups"
                :key="item.id"
                @click="setSelectedGroup(item)"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-menu
          top
          close-on-click
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!powerBIReports.length"
              :loading="loadingReports"
              v-bind="attrs"
              v-on="on">
              <span>Reports</span>
              <v-icon>$report</v-icon>
            </v-btn>
          </template>
          <v-list max-height="200">
            <v-list-item-group
              style="background-color: #ffffff;"
              color="primary"
            >
              <v-list-item
                v-for="item in powerBIReports"
                :key="item.id"
                @click="setSelectedReport(item)"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-menu
          top
          close-on-click
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!reportPages.length"
              :loading="loadingPages"
              v-bind="attrs"
              v-on="on"
            >
              <span>Pages</span>
              <v-icon>$pages</v-icon>
            </v-btn>
          </template>
          <v-list max-height="200">
            <v-list-item-group
              style="background-color: #ffffff;"
              color="primary"
            >
              <v-list-item
                v-for="item in reportPages"
                :key="item.Name"
                @click="setSelectedPage(item)"
              >
                <v-list-item-title>{{ item.displayName }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn
          :disabled="!selectedReport"
          @click="toggleReport = !toggleReport"
        >
          <span>Filters</span>
          <v-icon>$filter</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-container>
  </div>
</template>

<script>
import * as pbi from 'powerbi-client';
import { getGroups, getAllReports, getReportPages } from "@/api/power_bi";

const powerBIService = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory
)
const orientation = window.matchMedia("(orientation: portrait)");
export default {
  name: 'BI',

  data() {
    return {
      powerBIGroups: [],
      powerBIReports: [],
      selectedGroup: null,
      selectedReport: null,
      loadingGroups: false,
      loadingReports: false,
      loadingPages: false,
      toggleReport: false,
      reportPages: [],
      selectedPage: null,
      layoutType: pbi.models.LayoutType.MobilePortrait,
      report: null,
    }
  },
  watch: {
    toggleReport(value) {
      this.toggleFilterPane(value);
    }
  },
  async mounted() {
    await this.$store.dispatch('user/refreshAzureAccessToken');
    if (!orientation.matches) {
      this.layoutType = pbi.models.LayoutType.MobileLandscape;
    }
    orientation.addListener((event) => {
      this.layoutType = event.matches ? pbi.models.LayoutType.MobilePortrait
        : pbi.models.LayoutType.MobileLandscape;
      if (this.selectedReport) {
        this.changeReportLayout(this.layoutType);
      }
    });
    this.fetchGroups();
  },
  methods: {
    async changeReportLayout(layoutType) {
      const layoutSettings = {
        layoutType,
      };
      await this.report.updateSettings(layoutSettings);
    },
    async toggleFilterPane(value) {
      if (!this.report) return;
      const filterSettings = {
        panes: {
          filters: {
            expanded: value
          }
        }
      }
      await this.report.updateSettings(filterSettings);
    },
    setSelectedGroup(item) {
      this.selectedGroup = item.id;
      this.fetchReports(item.id);
    },
    setSelectedReport(item) {
      this.selectedReport = item.id;
      this.embedReport(item.id);
      this.fetchPages(item.id)
    },
    setSelectedPage(item) {
      this.selectedPage = item.Name;
      this.changeReportPage(item.Name);
    },
    embedReport(reportId) {
      const container = this.$refs.power_bi_container;
      const embedConfiguration = {
        accessToken: this.$store.state.user.msAccessToken,
        id: reportId,
        embedUrl: 'https://app.powerbi.com/reportEmbed',
        tokenType: pbi.models.TokenType.Aad,
        pageName: this.selectedPage,
        type: "report",
        settings: {
          layoutType: this.layoutType,
          panes: {
            filters: {
              expanded: false,
              visible: true
            }
          }
        }
      }
      this.report = powerBIService.embed(container, embedConfiguration);
      this.fetchPages(reportId);
    },
    changeReportPage(pageName) {
      const container = this.$refs.power_bi_container;
      const embedConfiguration = {
        accessToken: this.$store.state.user.msAccessToken,
        id: this.selectedReport,
        embedUrl: 'https://app.powerbi.com/reportEmbed',
        pageName,
        tokenType: pbi.models.TokenType.Aad,
        type: "report",
        settings: {
          layoutType: this.layoutType,
          panes: {
            filters: {
              expanded: false,
              visible: true
            }
          }
        }
      }
      this.report = powerBIService.embed(container, embedConfiguration);
    },
    async fetchReports(groupId) {
      this.loadingReports = true;
      try {
        const powerBIReports = await getAllReports(this.$store.state.user.msAccessToken, groupId);
        this.powerBIReports = powerBIReports.value;
      } finally {
        this.loadingReports = false;
      }
    },
    async fetchGroups() {
      this.loadingGroups = true;
      try {
        const powerBIGroups = await getGroups(this.$store.state.user.msAccessToken);
        this.powerBIGroups = powerBIGroups.value;
        this.powerBIGroups.sort((a, b) => a.name.localeCompare(b.name));
      } finally {
        this.loadingGroups = false;
      }
    },
    async fetchPages(reportId) {
      this.loadingPages = true;
      try {
        const reportPages = await getReportPages(this.$store.state.user.msAccessToken, reportId);
        this.reportPages = reportPages.value;
      } finally {
        this.loadingPages = false;
      }
    }
  }
};
</script>

<style>
</style>
