var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-container',{staticClass:"pa-0",staticStyle:{"height":"100%","justify-content":"center"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 flex-column flex-nowrap",staticStyle:{"height":"90%"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{ref:"power_bi_container",staticClass:"d-flex align-center justify-center",staticStyle:{"width":"100%","height":"calc(100vh - 136px)"}},[(!_vm.selectedReport)?_c('div',{staticClass:"text-center pt-4"},[_vm._v(" No report selected yet. Select your workspace and choose one of the reports. ")]):_vm._e()])])],1),_c('v-bottom-navigation',{attrs:{"color":"teal","grow":"","fixed":""}},[_c('v-menu',{attrs:{"top":"","close-on-click":"","offset-y":"","z-index":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loadingGroups}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Workspace")]),_c('v-icon',[_vm._v("$dashboard")])],1)]}}])},[_c('v-list',{attrs:{"max-height":"200"}},[_c('v-list-item-group',{staticStyle:{"background-color":"#ffffff"},attrs:{"color":"primary"},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}},_vm._l((_vm.powerBIGroups),function(item){return _c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.setSelectedGroup(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1),_c('v-menu',{attrs:{"top":"","close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.powerBIReports.length,"loading":_vm.loadingReports}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Reports")]),_c('v-icon',[_vm._v("$report")])],1)]}}])},[_c('v-list',{attrs:{"max-height":"200"}},[_c('v-list-item-group',{staticStyle:{"background-color":"#ffffff"},attrs:{"color":"primary"}},_vm._l((_vm.powerBIReports),function(item){return _c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.setSelectedReport(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1),_c('v-menu',{attrs:{"top":"","close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.reportPages.length,"loading":_vm.loadingPages}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Pages")]),_c('v-icon',[_vm._v("$pages")])],1)]}}])},[_c('v-list',{attrs:{"max-height":"200"}},[_c('v-list-item-group',{staticStyle:{"background-color":"#ffffff"},attrs:{"color":"primary"}},_vm._l((_vm.reportPages),function(item){return _c('v-list-item',{key:item.Name,on:{"click":function($event){return _vm.setSelectedPage(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.displayName))])],1)}),1)],1)],1),_c('v-btn',{attrs:{"disabled":!_vm.selectedReport},on:{"click":function($event){_vm.toggleReport = !_vm.toggleReport}}},[_c('span',[_vm._v("Filters")]),_c('v-icon',[_vm._v("$filter")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }